<template>
    <div v-if="translates && translates[langUrl]">
        <div class="main-info">
            <div class="main-info__label-container">
                <div class="main-info__label">{{ translates[langUrl].label_booking[lang] }}</div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <h1 class="main-info__title">{{ trip.name }}</h1>
                <div v-if="bloggersTrip" class="main-info__edit" @click="tripNameEdit"></div>
            </div>
            <!-- <div class="main-info__rating">
                <template v-if="trip.programs">{{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}</template> * 4,5 
                <img src="../../../assets/StarRating.svg" style="width: 14px; height: 14px; margin: 0 4px 0" /> (677)
            </div> -->
            <div v-if="trip.direction && trip.direction[0]" class="main-info__direction">
                <div class="main-info__direction__item">
                    {{ translates[langUrl].start[lang] }} 
                    {{ trip.direction[0].country ? trip.direction[0].country.title : '' }}{{ trip.direction[0].state ? (', ' + trip.direction[0].state.title) : '' }}{{ trip.direction[0].city ? (', ' + trip.direction[0].city.title) : '' }}</div>
                <div class="main-info__direction__item">
                    <template>{{ translates[langUrl].finish[lang] }}</template>
                    {{ trip.direction[trip.direction.length - 1] ? trip.direction[trip.direction.length - 1].country.title : '' }}<template style="margin-left: 10px">{{ trip.direction[trip.direction.length - 1].state ? (', ' + trip.direction[trip.direction.length - 1].state.title) : '' }}</template>{{ trip.direction[trip.direction.length - 1].city ? (', ' + trip.direction[trip.direction.length - 1].city.title) : '' }}
                </div>
            </div>
            <!-- <div v-if="!isDesktop" class="main-info__price">
                <div v-if="dateSelectItems && dateSelectItems.length !== 0" class="right-info-block__prices">
                    <div class="right-info-block__prices__sale">
                        <template v-if="activeArrival.discount && activeArrival.discount !== 0">
                            <div class="right-info-block__prices__default-price"><span v-html="priceFormated(cost(), activeArrival.currency)"></span></div>
                            <div class="right-info-block__prices__percent"> {{ activeArrival.discount }}%</div>
                        </template>
                    </div>
                    <div class="right-info-block__prices__final-price"><span v-html="priceFormated(priceWithSale, activeArrival.currency)"></span></div>
                    <div class="right-info-block__cost-and-duration__trip.programs.length">{{ trip.programs.length }} дней</div>
                    <BaseButton class="button-fill" style="width: 100%">Получить программу тура</BaseButton>
                    <BaseButton class="button-fill" style="width: 100%">Написать тревел-эксперту</BaseButton>
                </div>

            </div> -->
            <!-- <div>
                <div v-if="isDesktop" class="main-info__block-name">Тип тура</div>
                <div class="main-info__block main-info__trip-types"> 
                    <div v-if="trip.mainType !== ''" class="main-info__trip-types__trip-type">{{ formattedMainType }} </div>
                    <template v-for="(additionalType, index) in formattedAdditionalTypes">
                        <div :key="'trip-type-' + index" class="main-info__trip-types__trip-type">
                            <div v-if="trip.additionalType !== ''">{{ additionalType }}</div>
                        </div>
                    </template>
                </div>
                <div class="main-info__block main-info__trip-types">
                        <div class="main-info__block-name">Теги</div>
                        <template v-for="(tag, index) in trip.tags">
                            <div :key="'trip-type-' + index" class="main-info__trip-types__trip-type">
                                <div>{{ tag }}</div>
                            </div>
                        </template>
                    </div>
            </div> -->
                
            <!-- <div class="main-info__about" >
                <div class="main-info__about__container">
                    <div class="main-info__about__category">Тур оператор</div>
                    <div class="main-info__about__value"> {{ trip.tourOperator }} </div>
                </div>
                <div class="main-info__about__container">
                    <div class="main-info__about__category">Размер группы</div>
                    <div class="main-info__about__value"> 
                        <span v-if="trip.groupSizeFrom && trip.groupSizeFrom !== ''">от {{ trip.groupSizeFrom }}</span> 
                        <span v-if="trip.groupSizeTo && trip.groupSizeTo !== ''"> до {{ trip.groupSizeTo }}</span>
                    </div>
                </div>
                <div class="main-info__about__container">
                    <div class="main-info__about__category">Возраст</div>
                    <div class="main-info__about__value"> 
                        <span v-if="trip.groupAgeFrom && trip.groupAgeFrom !== ''">от {{ trip.groupAgeFrom }}</span>
                        <span v-if="trip.groupAgeTo && trip.groupAgeTo !== ''"> до {{ trip.groupAgeTo }}</span>
                    </div>
                </div>
                <div class="main-info__about__container">
                    <div class="main-info__about__category">Язык</div>
                    <div class="main-info__about__value"> {{ trip.language }} </div>
                </div>
                <div class="main-info__about__container">
                    <div class="main-info__about__category">ID тура</div>
                    <div class="main-info__about__value"> {{ trip.tourOperator }} </div>
                </div>
                <div class="main-info__about__container">
                    <div class="main-info__about__category">Гигиенические меры</div>
                    <div class="main-info__about__value"> {{ trip.hygiene }} </div>
                </div>
            </div> -->
            <!-- <BaseButton 
                    class="button-fill"
                    style="width:100%"
                    @click="writeToExpert"
                >Заказать тур</BaseButton> -->
            <!-- <div class="main-info__block-name">Участники тура</div>
            <div class="main-info__travelers">
                <div class="main-info__party">
                    <div class="main-info__party__title">Собираются поехать:</div>
                    <div class="main-info__party__people">
                        <img src="../../../assets/Ellipse1.png" />
                        <img src="../../../assets/Ellipse4.png" class="main-info__party__photo" />
                        <img src="../../../assets/Ellipse2.png" class="main-info__party__photo" />
                        <div class="main-info__party__people__more">ещё +3</div>
                    </div>
                </div>
                <div class="main-info__party">
                    <div class="main-info__party__title">Едут в тур:</div>
                    <div class="main-info__party__people">
                        <img src="../../../assets/Ellipse1.png" />
                        <img src="../../../assets/Ellipse4.png" class="main-info__party__photo" />
                        <img src="../../../assets/Ellipse2.png" class="main-info__party__photo" />
                        <div class="main-info__party__people__more">ещё +15</div>
                    </div>
                </div>
                <div class="main-info__party">
                    <div class="main-info__party__title">Друзья в этом туре:</div>
                    <div class="main-info__party__people">
                        <img src="../../../assets/Ellipse1.png" />
                        <img src="../../../assets/Ellipse4.png" class="main-info__party__photo" />
                        <img src="../../../assets/Ellipse2.png" class="main-info__party__photo" />
                        <div class="main-info__party__people__more">ещё +1</div>
                    </div>
                </div>
                <div class="main-info__travelers__like" @click="wantToGo"></div>
            </div> -->
            <!-- <BaseButton style="width:100%"> <img src="../../../assets/HeartPink.svg" style="width: 12px; height: 12px; margin-right: 5px;" /> Избранное</BaseButton> -->
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import BaseButton from '../BaseButton.vue';
    import { lastDigitToWord, isMobile } from '@/helpers'
    import { TRIP_TYPE } from '@/vars';

    export default {
        name: 'MainInfo',
        components: {
            BaseButton
        },
        props: {
            bloggersTrip: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            lastDigitToWord,
            TRIP_TYPE,
            isDesktop: false,
            langUrl: 'mainInfo'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('dictionaries', ['types']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            formattedMainType() {
                if(this.trip.mainType) {
                    return this.types.filter(item => (item.code === this.trip.mainType))[0].title;
                } else {
                    return ''
                }
                
            },
            formattedAdditionalTypes() {
                if(this.trip.additionalTypes) {
                    return this.types.filter(item => (this.trip.additionalTypes.indexOf(item.code) >= 0)).map(item => item.title);
                } else {
                    return ''
                }
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.isDesktop = !isMobile();
            },
            async writeToExpert() {
                this.$emit('click')
            },
            async wantToGo() {
                
            },
            tripNameEdit() {
                this.$emit('tripNameEdit')
            },
        }

    }
</script>

<style lang="scss">
.main-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.02em;
    // margin: 0 20px;
    &__travelers {
        // margin: 10px 0;
        // padding: 10px 0;
        display: flex;
        flex-direction: row;
        @media all and (max-width: 768px) {
            flex-direction: column;
        }
        &__like {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            background: url(../../../assets/HeartPink.svg);
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            @media all and (max-width: 768px) {
                display: none;
            }
        }
    }
    &__label-container {
        display: flex;
        flex-direction: row;
        // margin-top: 16px;
    }
    &__label {
        background: #48A7FF;
        color: #FFF;
        border-radius: 20px;
        padding: 4px 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
    }
    &__title {
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        color: #393939;
        margin: 12px 0 16px;
        word-wrap: break-word;
        @media all and(max-width: 768px) {
            max-width: 90%;
        }
    }
    &__edit {
        min-width: 25px;
        height: 25px;
        background: url(../../../assets/Edit.svg);
        cursor: pointer;
    }
    &__rating {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #3A4256;
    }
    &__direction {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin-top: 16px;
        &__item{
            font-weight: 500;
            // &:first-of-type {
                margin-bottom: 10px;
            // }
        }
    }
    &__block {
        margin-bottom: 10px;
    }
    &__block-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin-bottom: 12px;
    }
    &__price {
        width: 100%;
        height: 100vw;
    }
    &__trip-types {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &__trip-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            background: #F6F9FB;
            border-radius: 10px;
            margin: 0 5px 5px 0;
            padding: 6px 17px 6px;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.02em;
            color: #525B77;

            &:last-of-type {
            margin-right: 0;
            }
            @media all and (min-width: 768px) {
                background: #EBEEF2;
                border-radius: 10px;
                font-size: 13px;
                line-height: 16px;
                color: #525B77;
            }
        }
    }
    &__about {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 16px;
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 8px 0;
            width: 50%;
        }
        &__category {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            color: #9FA5AD;
            margin-bottom: 6px;
        }
        &__value {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
    }
    &__party {
        margin-right: 35px;
        @media all and (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 15px;
        }
        &__title {
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            color: #9FA5AD;
            margin-bottom: 5px;
            @media all and (max-width: 768px) {
                margin-bottom: 10px;
            }
        }
        &__people {
            display: flex;
            align-items: center;
            &__more {
                font-weight: normal;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                margin-left: 9px;
                color: #D80765;
            }
        }
        &__photo {
            margin-left: -8px;
        }
    }
}
</style>


