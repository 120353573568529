<template>
    <div class="popup-no-presentation" v-if="translates && translates[langUrl]">
        <div class="popup-no-presentation__sended">
            <div class="popup-no-presentation__sended__title">{{ name }}, {{ translates[langUrl].title[lang] }}</div>
            <div class="popup-no-presentation__close" @click="close"></div>
            <div class="popup-no-presentation__sended__text">
                {{ translates[langUrl].text_part_1[lang] }} 
                <a @click="writeToExpert" style="white-space: nowrap;">{{ translates[langUrl].text_part_2[lang] }}</a>
                {{ translates[langUrl].text_part_3[lang] }}</div>
            <BaseButton
                class="button-fill"
                style="width:300px"
                @click="close"
            >{{ translates[langUrl].button[lang] }}</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseButton from '../../components/common/BaseButton.vue'
    import BaseTextField from '../../components/common/BaseTextField.vue'
    import BaseTextArea from '../../components/common/BaseTextArea.vue'

    export default {
        name: 'PopupNoPresentation',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            BaseTextArea
        },
        data: () => ({
            secondsLeft: 0,
            intervalId: undefined,
            langUrl: 'popupNoPresentation'
        }),
        props: {
            text: '',
            name: ''
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
        },
        methods: {
            close() {
                this.$emit('close')
                this.formSended = false
            },
            writeToExpert() {
                this.$emit('writeToExpert')
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss">
    .popup-no-presentation {
        position: relative;
        max-width: 600px;
        width: 100%;
        padding: 50px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        @media all and (max-width: 768px) {
            padding: 36px 20px;
            border-radius: 12px;
        }
        &__page-name {
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 30px;
        }
        &__save {
            width: 100%;
        }
        &__personal-info {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #808080;
            margin-top: 12px;
            margin-left: 5px;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__sended {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 12px;
            }
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin-bottom: 25px;
                // max-width: 300px;
            }
        }
    }
</style>
